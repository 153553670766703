import React from 'react';
import { PopUpModal, ThemeUtils } from '@rentecarlo/component-library';
import { TimeoutIcon } from 'assets';
import { useTheme } from 'styled-components';

interface RetryRefundModalProps {
  show: boolean;
  onClick: () => void;
}

const RetryRefundModal: React.FC<RetryRefundModalProps> = ({ show, onClick }) => {
  const theme = useTheme();
  return (
    <PopUpModal
      data-testid='save-modal'
      id='save-modal'
      visible={show}
      theme={theme as ThemeUtils.Theme}
      maxWidth='480px'
      image={TimeoutIcon}
      title='Uh oh!'
      firstButtonId='cancel-try-again'
      firstButtonChildren='Try again'
      firstButtonOnClick={onClick}
    >
      It seems you’ve been on this page for a little while which means the time left on your
      insurance has changed. Hit the button below to try again.
    </PopUpModal>
  );
};

export default RetryRefundModal;
