import React from 'react';
import { ContactDetailsInput } from 'components/organisms';
import { Column, Container } from './assets/styles';

const ContactDetails: React.FC = () => {
  return (
    <Column>
      <Container>
        <ContactDetailsInput />
      </Container>
    </Column>
  );
};

export default ContactDetails;
