/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled from 'styled-components';

interface PillButtonProps {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  height?: number;
  onClick?: () => void;
  paddingHorizontal?: number;
  textColor?: string;
  hoverTextColor?: string;
}

interface PillButtonTextInterface {
  fontSize?: number;
  fontWeight?: string;
}

export const Button = Styled.button<PillButtonProps>`
  height: ${({ height }) => height || 30}px;
  width: 100%;
  border-radius: 17px;
  color: ${(props) => props.textColor};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.backgroundAlt};
  border: ${({ borderColor, borderWidth, theme }) =>
    `${borderWidth}px solid ${borderColor || theme.linkPrimary}`};
  padding: 2px ${({ paddingHorizontal }) => paddingHorizontal || 18}px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
  &:hover {
    background-color: ${({ textColor, theme }) => textColor || theme.linkPrimary};
    color: ${({ hoverTextColor, theme }) => hoverTextColor || theme.logoPrimary};
  }
`;

export const PillButtonText = Styled.span<PillButtonTextInterface>`
  font-size: ${({ fontSize }) => fontSize || 16}px;
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  line-height: 20px;
  text-align: center;
`;
