import Styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

import { Fonts } from 'utils/config';

export const ProfileCardContainer = Styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

export const Container = Styled.div`
  margin-top: 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${MediaQuery.mobile(css`
    margin-top: 32px;
  `)}
`;

export const ContentContainer = Styled.div`
  display: flex;
  flex-direction: column;
  ${MediaQuery.mobile(css`
    flex-shrink: 0;
  `)}
`;

export const CardRowContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  font-family: ${Fonts.standard};
  color: ${({ theme }): string => theme.textPrimary};
`;

export const CardRowTitle = Styled.div`
  font-weight: bold;
  color: ${({ theme }): string => theme.textPrimary};
`;

export const CardRecordSecondary = Styled.div`
  color: ${({ theme }): string => theme.textSecondaryAlt};
  font-family: ${Fonts.standard};;
  font-size: 14px;
`;

export const CardContentContainer = Styled.div`
  padding: 20px 20px 10px 20px;
  ${MediaQuery.mobile(css`
    margin-top: 18px;
  `)}
`;

export const LicenceTextContainer = Styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const Link = Styled.a`
  color: ${({ theme }): string => theme.linkPrimary};
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
`;

export const LicenceContainer = Styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = Styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-right: -15px;
`;

export const TooltipTextContainer = Styled.div`
  align-items: flex-start;
`;

export const TooltipText = Styled.div`
  color: ${({ theme }): string => theme.textPrimary};
  font-family: proxima-soft;
  font-size: 14px;
  margin-top: 8px;
  line-height: 16px;
`;
