import Styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';
import Email from './email.svg';
import Telephone from './telephone.svg';

export const Container = Styled.div`
  margin-top: 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${MediaQuery.mobile(css`
    margin-top: 32px;
  `)}
`;

export const SubContainer = Styled.div`
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 48px;
`;

export const ResponsiveContainer = Styled.div`
  display: flex;
  flex-direction: column;
  ${MediaQuery.desktop(css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `)}
`;

export const OptionContainer = Styled.div`
  color: ${({ theme }): string => theme.textPrimary};
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${MediaQuery.desktop(css`
    flex-basis: 50%;
  `)}
`;

export const OptionLabel = Styled.div`
  flex-grow: 1;
  margin-left: 16px;
  font-size: 16px;
  font-weight: bold;
`;

export const HorizontalDivider = Styled.div`
  height: 0;
  border-top: ${({ theme }): string => theme.divider};
  margin-left: 16px;
  margin-right: 16px;
  flex-basis: 1px;
  ${MediaQuery.desktop(css`
    display: none;
  `)}
`;

export const VerticalDivider = Styled.div`
  width: 0;
  height: 49px;
  border-left: ${({ theme }): string => theme.divider};
  margin-top: 8px;
  margin-bottom: 8px;
  flex-basis: 1px;
  ${MediaQuery.tablet(css`
    display: none;
  `)}
`;

export const EmailIcon = Styled.img.attrs(() => ({
  alt: 'Email icon',
  src: Email,
}))`
  margin-left: 24px;
`;

export const TelephoneIcon = Styled.img.attrs(() => ({
  alt: 'Telephone icon',
  src: Telephone,
}))`
  margin-left: 24px;
`;
