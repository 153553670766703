import React, { ReactElement, useState, useEffect } from 'react';
import { Field, Formik, Form } from 'formik';
import { useTheme } from 'styled-components';
import {
  Address,
  AddressCard,
  Button,
  Validation,
  OccupationTypes,
  Occupations,
  FormErrorBanner,
  TextLink,
} from '@rentecarlo/component-library';
import { InputAddressSpeechBox, FormItem } from 'components/atoms';
import {
  InputField,
  OccupationTypeSelectField,
  InputSearchField,
  OccupationDropDownField,
} from 'components/molecules';
import { ProfileClient } from 'services';
import { ComponentProps } from './ContactDetailsInput.container';
import useAddressLookup from './useAddressLookup';
import { PostcodeAddressContainer, OccupationContainer } from './assets/styles';
import useOccupationLookup from './useOccupationLookup';
import UpdateEmailInput from './UpdateEmailInput';

export interface FormValues {
  occupationType?: OccupationTypes;
  occupation: string;
  phoneNumber: string;
  email: string;
  newEmail?: string;
  retypeEmail?: string;
  password?: string;
  address: Address;
  postcode: Address['Postcode'];
  canEdit: boolean;
}

enum EditAddressMode {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

enum ShowOccupationModal {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

type OptionType = {
  name: string;
  value: string;
};

const getOccupationOptions = (
  occupationType?: OccupationTypes,
  occupations?: Occupations,
): Array<OptionType> | [] => {
  if (occupationType && occupations) {
    return occupations[occupationType]
      .sort()
      .map((occupation) => ({ name: occupation, value: occupation }));
  }
  return [];
};

const ContactDetailsInput = (props: ComponentProps): ReactElement => {
  const theme = useTheme();
  const [addressMode, setAddressMode] = useState(EditAddressMode.VIEW);
  const [showOccupationModal, setShowOccupationModal] = useState(ShowOccupationModal.CLOSED);
  const [submitError, setSubmitError] = useState(false);
  const [formValidating, setFormValidating] = useState(false);

  const toggleAddressCard = (): void => {
    if (EditAddressMode.VIEW === addressMode) {
      setAddressMode(EditAddressMode.EDIT);
    } else {
      setAddressMode(EditAddressMode.VIEW);
    }
  };

  const { submit, occupationType, occupation, phoneNumber, email, address } = props;
  const [currentAddress, setCurrentAddress] = useState({ ...address });
  const [currentAddressKey, setCurrentAddressKey] = useState(-1);

  const canEdit = true;
  useEffect(() => {
    if (address) {
      setCurrentAddress(address);
    }
  }, [address]);

  const initialValues: FormValues = {
    occupationType,
    occupation,
    phoneNumber,
    email,
    newEmail: '',
    retypeEmail: '',
    password: '',
    address,
    postcode: address?.Postcode, // Cannot access object.property inside Formik field
    canEdit,
  };

  const { state, getAddresses, resetErrors } = useAddressLookup();

  const addresses =
    state.foundAddresses.length > 0
      ? state.foundAddresses.map((foundAddress, index) => {
          return {
            ...foundAddress,
            key: String(index),
          };
        })
      : [];

  const updateCurrentAddress = (key: string) => {
    setCurrentAddress(addresses[parseInt(key)]);
  };

  const { occupationState, getOccupations } = useOccupationLookup();

  const saveButtonLabel = !submitError ? 'Save details' : 'Errors on page';

  return (
    <>
      {addressMode === EditAddressMode.VIEW && (
        <FormItem label='Your address' bottomMargin='57px'>
          <AddressCard
            id='addressCard'
            address={currentAddress}
            toggleAddressCard={() => setAddressMode(EditAddressMode.EDIT)}
            disabled={!canEdit}
          />
        </FormItem>
      )}

      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values: FormValues): void => submit(values)}
      >
        {({
          errors,
          isValid,
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          setFieldError,
          validateForm,
        }): ReactElement => {
          const validateFormFields = async () => {
            setFormValidating(true);
            await validateForm();
            let isEmailError = true;
            if (values.newEmail) {
              try {
                await ProfileClient.checkEmailExists(values.newEmail);
                setFieldError(
                  'newEmail',
                  'There seems to be an account already registered with this email address. Please try a different email address',
                );
              } catch (error) {
                // do nothing, email doesn't exist
                isEmailError = false;
              }
            } else {
              isEmailError = false;
            }
            if (isEmailError) {
              setSubmitError(true);
            }
            setFormValidating(false);
            return isEmailError;
          };

          return (
            <Form
              onKeyUp={() => {
                if (isValid) setSubmitError(false);
              }}
            >
              {addressMode === EditAddressMode.EDIT && (
                <FormItem label='Your address' bottomMargin='57px'>
                  <PostcodeAddressContainer>
                    <Field
                      id='postcode'
                      name='postcode'
                      aria-label='postcode'
                      placeholder='e.g. CF10 2EH'
                      value={values.postcode}
                      component={InputSearchField}
                      validate={Validation.isCoveredPostcode}
                      onSearch={() => {
                        getAddresses(values.postcode);
                      }}
                      onChange={(event: React.ChangeEvent) => {
                        handleChange(event);
                        resetErrors();
                      }}
                      maxLength={8}
                      alwaysCapitalised
                      pristine={false}
                      error={state.addressError}
                    />
                    <Field
                      id='address'
                      name='address'
                      aria-label='address'
                      component={InputAddressSpeechBox}
                      addresses={addresses}
                      visible={addresses.length > 0}
                      text='Please select your address'
                      footerText={`${addresses.length} addresses found`}
                      value={values.address}
                      onChange={(key: string) => {
                        updateCurrentAddress(key);
                        setFieldValue('address', addresses[parseInt(key)]);
                        setCurrentAddressKey(parseInt(key));
                      }}
                      selectedAddress={currentAddressKey}
                      toggleAddressCard={toggleAddressCard}
                      touched
                    />
                  </PostcodeAddressContainer>
                </FormItem>
              )}
              <FormItem label='Your phone number' bottomMargin='57px'>
                <Field
                  id='phoneNumber'
                  name='phoneNumber'
                  aria-label='phoneNumber'
                  placeholder='e.g. 01234567890'
                  value={values.phoneNumber}
                  component={InputField}
                  type='tel'
                  validate={Validation.isPhoneNumber}
                  maxLength={12}
                  bold
                  disabled={!canEdit}
                  submitError={submitError}
                />
              </FormItem>
              <UpdateEmailInput values={values} canEdit={canEdit} submitError={submitError} />
              <FormItem label='Your occupation'>
                <OccupationContainer>
                  <Field
                    id='occupationType'
                    name='occupationType'
                    aria-label='occupationType'
                    component={OccupationTypeSelectField}
                    value={values.occupationType}
                    occupation={values.occupation ?? ''}
                    toggleModal={() => getOccupations()}
                    onChange={(selectedOccupationType: OccupationTypes): void => {
                      if (values.occupationType !== selectedOccupationType) {
                        getOccupations();
                        setFieldValue('occupation', '');
                        setFieldValue('occupationType', selectedOccupationType);
                        getOccupationOptions(occupationType, occupationState.foundOccupations);
                        setShowOccupationModal(ShowOccupationModal.OPEN);
                      }
                    }}
                    onClick={() => {
                      getOccupations();
                      getOccupationOptions(occupationType, occupationState.foundOccupations);
                      setShowOccupationModal(ShowOccupationModal.OPEN);
                    }}
                    disabled={!canEdit}
                  />
                  <Field
                    id='occupation'
                    name='occupation'
                    aria-label='occupation'
                    component={OccupationDropDownField}
                    value={values.occupation}
                    occupations={getOccupationOptions(
                      values.occupationType,
                      occupationState.foundOccupations,
                    )}
                    onChange={(selectedOccupation: string): void => {
                      setFieldValue('occupation', selectedOccupation);
                      setShowOccupationModal(ShowOccupationModal.CLOSED);
                    }}
                    validate={Validation.isRequired}
                    show={showOccupationModal === ShowOccupationModal.OPEN}
                    close={() => setShowOccupationModal(ShowOccupationModal.CLOSED)}
                  />
                </OccupationContainer>
              </FormItem>
              <>
                This <b>will not</b> update any active or upcoming policies. To do that you need to{' '}
                <TextLink href='https://www.veygo.com/contact'>contact us</TextLink>.
                <br /> <br />
              </>
              <Button
                type='button'
                borderColor={((submitError as unknown) as string | undefined) && theme.errorPrimary}
                backgroundColor={
                  ((submitError as unknown) as string | undefined) && theme.errorPrimary
                }
                backgroundColorHover={
                  ((submitError as unknown) as string | undefined) && theme.errorPrimary
                }
                id='saveButton'
                height={64}
                onClick={() => {
                  validateFormFields().then((err) => {
                    const formErrorExists = Object.keys(errors).length > 0;
                    if (!err && !formErrorExists) {
                      handleSubmit();
                    }
                  });
                }}
                disabled={!canEdit}
                isLoading={formValidating}
                circleLoader
              >
                {saveButtonLabel}
              </Button>
              <FormErrorBanner show={submitError} formErrors={errors} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactDetailsInput;
