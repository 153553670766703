import { SagaIterator } from 'redux-saga';
import { call, delay, fork, put, select, take } from 'redux-saga/effects';
import { BackendClient } from 'services';
import { AppState } from 'state';
import { NotificationState } from 'state/notifications/types';
import * as actionTypes from './actionTypes';
import { actionCreators } from './actions';

function* updatePreferences(notifications: NotificationState): SagaIterator {
  try {
    yield call(BackendClient.updateCustomerMarketingPreferences, {
      phoneConsent: notifications.phoneConsent,
      emailConsent: notifications.emailConsent,
    });
    yield put(actionCreators.updateSuccessful());
  } catch (error) {
    yield delay(200);
    yield put(actionCreators.undoNotifications());
    yield put(actionCreators.updateFailed());
  }
}

export default function* watchNotificationToggles(): SagaIterator {
  while (true) {
    yield take([actionTypes.TOGGLE_NOTIFICATION]);
    const notifications = yield select((state: AppState) => state.notifications.present);
    yield fork(updatePreferences, notifications);
  }
}
